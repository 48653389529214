<template>
  <div class="wrapper-content">
    <div class="partners-title">
    </div>
    <div class="partners-content">
      <div class="pay-result">
        <h3 v-if="type === 'success'">
          <span class="iconfont icon-chenggong"></span>{{ memberPrice == 0 ? "申请成功" :memberPrice == 1? '报名成功' : "支付成功" }}
        </h3>
        <h3 v-else><span class="iconfont icon-shibai"></span> 支付失败</h3>
        <el-button type="primary" class="btn-look" @click="lookSignup">查看</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pay-result",
  components: {},
  props: ["payResult", "url"],
  data() {
    return {
      type: '',
      title: '',
      memberPrice: this.$route.query.memberPrice,
    };
  },
  mounted() {
    // this.init();
  },
  created() {
    this.type = this.$route.query.type || this.payResult
    this.title = this.$route.query.title
    this.memberPrice = this.$route.query.memberPrice
  },
  methods: {
    // init() {
    //   const self = this;
    //   let sign = self.sign;
    //   self.$store.commit("showLoading");
    //   getHome({ sign }).then((res) => {
    //     if (res.code == 200) {
    //       self.$store.commit("hideLoading");
    //     } else {
    //       self.$toast(res.msg);
    //     }
    //   });
    // },
    back() {},
    lookSignup() {
      this.$store.dispatch("saveSideActive", '/order');
      this.$router.push("/order");
      // if(this.title=='我的会员'){
      //   this.$router.push("/members");
      // }else {
      //   this.$router.push("/order");
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.partners-title {
  margin-top: 20px;
  padding: 0 24px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  height: 46px;

  h2 {
    border-bottom: solid 1px #e5e5e5;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
  }
}
.partners-content {
  padding: 24px 24px 54px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  min-height: 700px;
  margin-bottom: 20px;
}
.pay-result {
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  margin-bottom: 60px;

  h3 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 44px;
    font-weight: bold;
    color: #333333;

    span {
      font-size: 44px;
      padding-right: 14px;
      vertical-align: middle;
    }

    .icon-chenggong {
      color: #5bc001;
    }

    .icon-shibai {
      color: #ff3f35;
    }
  }
}
</style>
