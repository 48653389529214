<template>
  <el-dialog
    :visible.sync="visible"
    title="批量导入"
    class="dialog-detail"
    width="500px!important"
    top="15vh"
  >
    <el-form ref="leadingForm" label-width="120px" label-position="left">
      <el-form-item label="上传文件：">
        <el-upload
          class="upload-demo"
          :headers="headers"
          :action="upDataUrl"
          :on-remove="handleRemove"
          :limit="1"
          :accept="acceptFile"
          :show-file-list="true"
          :file-list="fileList"
          :on-success="handleSuccess"
          :on-exceed="handleExceed"
        >
          <el-button size="small" type="primary" :loading="btnLoading">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">（只能上传xlsx/xls文件，且不超过10M）</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="模板下载：">
        <a :href="downloadUrl" class="download" @click="download">模板.xls</a>
      </el-form-item>
      <el-form-item class="applyOrgBtn">
        <el-button @click="closeLeadingForm">取消</el-button>
        <el-button type="primary" @click="onSubmit">确认</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { importPersonnel } from "@/api/events";
import { importTrainPersonnel } from "@/api/train"
export default {
  props: {
    t:{
      type: String,
      required: false
    },
    fileUrl:{
      type: String,
      required: true
    },
    acceptFile:{
      type: String,
      required: true
    },
    typeStr:{
      type: String,
      required: true
    },
  },
  data() {
    return {
      files: null,
      downloadUrl: "",
      visible: false,
      headers:{},
      btnLoading: false,
      upDataUrl: process.env.VUE_APP_API_URL + '/api/file/Uploader/annex',
      fileList: [],
    }
  },
  methods: {
    init() {
      this.visible = true
      this.token = window.localStorage.token
      this.headers = {
        Authorization: window.localStorage.token
      }
      this.files = null
      this.fileList = []
    },
    // 导入成功
    handleSuccess(res, file) {
      this.files = file
      // this.btnLoading = true
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // this.files = formData
      // this.$message({
      //   message: res.msg,
      //   type: 'success',
      //   duration: 1500,
      //   onClose: () => {
      //     this.visible = false
      //     this.$emit('leading', this.files)
      //     this.btnLoading = false
      //   }
      // })
    },
    // 导入删除
    handleRemove() {
      this.files = null
    },
    handleExceed() {
      this.$message(
        {
          type: 'warning',
          message: '限制上传一个文件'
        }
      )
    },
    download() {
      this.downloadUrl = process.env.VUE_APP_API_URL + this.t
    },
    onSubmit() {
      this.btnLoading = true
      const formData = new FormData()
      formData.append('file', this.files.raw)
      this.btnLoading = true
      if(this.typeStr == 'events'){
        importPersonnel(formData,3).then(res=>{
          if(res.code == 200){
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false
                this.$emit('leading', this.files)
                this.btnLoading = false
              }
            })
          }else {
            this.$message({
              message: res.msg,
              type: 'warning',
              duration: 1500,
              onClose: () => {
                this.btnLoading = false
              }
            })
          }
        })
      }else if(this.typeStr == 'train'){
        importTrainPersonnel(formData,1).then(res=>{
          if(res.code == 200){
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false
                this.$emit('leading', this.files)
                this.btnLoading = false
              }
            })
          }else {
            this.$message({
              message: res.msg,
              type: 'warning',
              duration: 1500,
              onClose: () => {
                this.btnLoading = false
              }
            })
          }
        })
      }

    },
    closeLeadingForm() {
      this.visible = false
      this.$emit('close', this.visible)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog{
  margin-top: 15vh!important;
  width: 500px!important;;
  max-height: 30px!important;
}
.download{
  color: #1e50ae;
}
.applyOrgBtn{
  text-align: right;
}
</style>
